<template>
    <div class="dsVideoRank">
        <div class="dsVideoRank-header">
            <div class="dsVideoRank-underline">
            <div class="listChoices">
                <el-radio-group v-model="radio" size="medium" @change="selectElectricityVideo" fill="#2338E6">
                    <el-radio-button label="日榜" style="height: 32px"></el-radio-button>
                    <el-radio-button label="周榜"></el-radio-button>
                    <el-radio-button label="月榜"></el-radio-button>
                </el-radio-group>
            </div>
            <div class="dateSelect">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        @change="selectDayDate"
                        v-show="radio == '日榜'"
                        :picker-options="pickerDisabled"
                        v-model="selectDateDay"
                        format="MM月dd日"
                        type="date"
                        placeholder="选择日期">
                </el-date-picker>
                <el-date-picker
                        ref="menuWeek"
                        id="getWeek"
                        value-format=""
                        @change="selectWeekDate"
                        v-show="radio == '周榜'"
                        :picker-options="pickerDisabled"
                        v-model="selectDateWeek"
                        type="week"
                        format="第WW周"
                        placeholder="选择周">
                </el-date-picker>
                <el-date-picker
                        value-format="yyyy-MM"
                        v-show="radio == '月榜'"
                        @change="selectMonthDate"
                        :picker-options="pickerDisabled"
                        v-model="selectDateMonth"
                        type="month"
                        format="第MM月"
                        placeholder="选择月">
                </el-date-picker>
            </div>
            <div class="searchContent">
                <el-input
                        v-show="isProduct == 1"
                        class="searchInput"
                        placeholder="请输入您要搜索的内容"
                        prefix-icon="el-icon-search"
                        v-model="searchContent">
                </el-input>
                <el-input
                        v-show="isProduct == 2"
                        class="searchInput"
                        placeholder="请输入您要搜索的内容"
                        prefix-icon="el-icon-search"
                        v-model="searchContentTwo">
                </el-input>
            </div>
            </div>
            <div class="commodityClassify">
<!--                <div class="commodityClassify-underline">-->
<!--                    <span class="span-spfl">商品分类：</span>-->
<!--                    <span :class="isSelectId ? 'span-all':'span-alls'" @click="getAllList">全部</span>-->
<!--                    <div class="commodityClassify-box" style="display: inline-block" v-for="item in GoodsVideoClassList" :key="item.id">-->
<!--                        <el-dropdown class="commodityClassify-dro" placement="bottom">-->
<!--                            <span style="padding:0 10px" :class="isSelectId == item.id ? 'elDropdown': ''">-->
<!--                                {{item.class_name}}<i style="margin-left: 3px;" class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--                            </span>-->
<!--                            <el-dropdown-menu class="dropdownMenu" slot="dropdown">-->
<!--                                <el-dropdown-item class="dropdownItem" @click.native="secondaryClass(item,className)" v-for="className in item.children" :key="className.id" style="float: left;">{{className.class_name}}</el-dropdown-item>-->
<!--                            </el-dropdown-menu>-->
<!--                        </el-dropdown>-->
<!--                    </div>-->
<!--                    <div class="fill"></div>-->
<!--                </div>-->
                <el-table v-show="isProduct == 1" style="width: 100%" class="tableList" :data="queryOne(searchContent)" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333333',fontSize:'14px',fontWeight:'400'}" :cell-style="{fontSize: '12px',color: '#333'}">
                    <el-table-column class="tableList-item" prop="rank" label="排行" align="center" width="82">
                        <template slot-scope="scope">
                            <span class="ranking-num">{{scope.row.rank}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="video_cover_url" label="视频" align="center" width="200">
                        <template slot-scope="scope">
                            <img class="videoImg" :src="scope.row.video_cover_url" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="" align="left" width="">
                        <template slot-scope="scope">
                            <p class="describe-title">{{scope.row.desc}}</p>
                            <p class="describe-content">关联商品：<span>{{scope.row.promotions}}</span></p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="digg_count" label="达人" align="center" width="150">
                        <template slot-scope="scope">
                            <span class="text-overflow">{{scope.row.nickname}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="digg_count" label="点赞数" align="center" width="100"></el-table-column>
                    <el-table-column prop="share_count" label="转发数" align="center" width="100"></el-table-column>
                    <el-table-column prop="comment_count" label="评论数" align="center" width="100"></el-table-column>
                    <el-table-column prop="volume" label="预估销量" align="center" width="140">
                        <template slot-scope="scope">
                            <span>{{scope.row.volume}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="预估销售额" align="center" width="140">
                        <template slot-scope="scope">
                            <span>{{scope.row.amount}}</span>
                        </template>
                    </el-table-column>
<!--                    <el-table-column prop="nickname" label="作者" align="center">-->
<!--                        <template slot-scope="scope">-->
<!--                            <p class="author-name">{{scope.row.nickname}}</p>-->
<!--                            <p class="author-fans">{{scope.row.follower_count}}粉丝</p>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                    <el-table-column label="操作" align="center" width="190">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" class="data-icon" @click="toVideoDetail(scope.row)"><i class="iconfont">&#xe6a1;</i></span>
                            <a target="_blank" :href="scope.row.video_share_url"><span class="play-icon"><i class="iconfont">&#xe63a;</i></span></a>
                            <span @click="toAnalysis(scope.row,'goodsAnalyzes')" style="cursor: pointer" class="chart-icon"><i class="iconfont">&#xe632;</i></span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table v-show="isProduct == 2" style="width: 100%" class="tableList" :data="queryTwo(searchContentTwo)" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333333',fontSize:'14px',fontWeight:'400'}" :cell-style="{fontSize: '12px',color: '#333'}">
                    <el-table-column prop="rank" label="排行" align="center" width="82">
                        <template slot-scope="scope">
                            <span class="ranking-num">{{scope.row.rank}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="video_cover_url" label="视频" align="center">
                        <template slot-scope="scope">
                            <img class="videoImg" :src="scope.row.video_cover_url" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="" align="center" width="295">
                        <template slot-scope="scope">
                            <p class="describe-title">{{scope.row.desc}}</p>
                            <p class="describe-content">关联商品：<span>{{scope.row.promotions}}</span></p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="digg_count" label="点赞总数 | 日增" align="center">
                        <template slot-scope="scope">
                            <span class="likeAdd-like">{{scope.row.digg_count}}</span>
                            <span class="likeAdd-add">{{scope.row.daily_grow_digg_count}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="product_order_account" label="抖音月销量" align="center" width="100"></el-table-column>
                    <el-table-column prop="sales" label="全网销量" align="center" width="100"></el-table-column>
                    <el-table-column prop="price" label="售价" align="center" width="100">
                        <template slot-scope="scope">
                            <span>￥{{scope.row.price}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="commission_rate" label="佣金" align="center" width="100">
                        <template slot-scope="scope">
                            <span>{{scope.row.commission_rate}}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="发布时间" align="center">
                        <template slot-scope="scope">
                            <span>{{dateFormat(scope.row.create_time)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nickname" label="作者" align="center">
                        <template slot-scope="scope">
                            <p class="author-name">{{scope.row.nickname}}</p>
                            <p class="author-fans">{{scope.row.follower_count}}粉丝</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="190">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" class="data-icon" @click="toVideoDetailTwo(scope.row)"><i class="iconfont">&#xe6a1;</i></span>
                            <a target="_blank" :href="scope.row.video_share_url"><span class="play-icon"><i class="iconfont">&#xe63a;</i></span></a>
                            <span style="cursor: pointer" class="chart-icon"><i class="iconfont">&#xe632;</i></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                    v-if="productList.length>0"
                    style="margin-bottom: 10px"
                    class="pages-center"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>

    </div>
</template>

<script>
    export default {
        name: "DsVideoRanking",
        data(){
          return {
              isSelectId:'',
              pickerDisabled:{
                  // 禁选当天之后的日期
                  disabledDate: (time) => {
                      return time.getTime() > new Date() * 1 + 600 * 1000;
                  }
              },
              isListDate:'',
              selectDateDay:'', //日
              selectDateWeek:'', //周
              selectDateMonth:'', //月
              radio:'日榜',
              dateValue:'',
              dateList:[{
                  value: '选项1',
                  label: '10月14日'
              }, {
                  value: '选项2',
                  label: '10月15日'
              },],
              searchContent:'',
              searchContentTwo:'',
              commodityClassify:['服饰鞋包','护肤美容','日用百货','家居家装','数码家电','运动户外','汽车','餐饮食品','母婴儿童','钟表首饰','文化娱乐','鲜花园艺','宠物服务','医药保健'],
              productList:[], //视频商品
              productListTwo:[],
              isProduct:1,
              GoodsVideoClassList:[], // 视频分类
              //分页
              adminPages: {
                  currentPageNum: 1,
                  eachPageNum: 10,
                  total: 0
              },
              lweekVal:'',
          }
        },
        created() {
            this.getElectricityVideo();
            this.getGoodsVideoClass();
        },
        methods: {
            getAllList(){
              this.isProduct = 1;
              this.getElectricityVideo()
            },
            queryOne(searchContent){
                // if(this.productList.length>0){
                //     return this.productList.filter(item=>{
                //         if(item.desc.includes(searchContent)){
                //             return item
                //         }
                //     })
                // }
                return this.productList.filter(item=>{
                    if(item.desc.includes(searchContent)){
                        return item
                    }
                })
            },
            queryTwo(searchContentTwo){
                // if(this.productListTwo.length>0){
                //     return this.productListTwo.filter(item=>{
                //         if(item.desc.includes(searchContentTwo)){
                //             return item
                //         }
                //     })
                // }
                return this.productListTwo.filter(item=>{
                    if(item.desc.includes(searchContentTwo)){
                        return item
                    }
                })
            },
            // 选择分类
            secondaryClass(item,parms){
                console.log(267,item)
                console.log(268,parms)
                this.isSelectId = item.id;
                this.isProduct = 2;
                this.productListTwo = [];
                let className = parms.class_name;
                this.productList.map(item=>{
                    if(item.our_category_2 == className){
                        this.productListTwo.push(item)
                    }
                })
                this.adminPages.total = this.productListTwo.length;
            },
            // 监听搜索输入
            inputFunc(){},
            //时间格式化函数，此处仅针对yyyy-MM-dd hh:mm:ss 的格式进行格式化
            dateFormat:function(time) {
                var date=new Date(time*1000);
                var year=date.getFullYear();
                var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
                var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
                var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
                var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
                var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
                // 拼接
                return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
            },
            // 时间戳为13位
            dateFormatTwo:function(time) {
                var date=new Date(time);
                var year=date.getFullYear();
                var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
                var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
                var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
                var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
                var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
                // 拼接
                return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
            },
            selectElectricityVideo(){
                // console.log(311,this.radio)
                this.isProduct = 1;
                this.getElectricityVideo()
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getElectricityVideo()
            },
            //获取电商视频列表
            getElectricityVideo(){
                let param = {};
                if(this.radio == '日榜'){
                    if(this.isListDate === ''){
                        param = {
                            date_type:'day',
                            page: this.adminPages.currentPageNum,
                            limit: this.adminPages.eachPageNum //页面显示条数
                        };
                    } else {
                        param = {
                            date_type:'day',
                            date:this.isListDate,
                            page: this.adminPages.currentPageNum,
                            limit: this.adminPages.eachPageNum //页面显示条数
                        };
                    }
                } else if(this.radio == '周榜'){
                    if(this.isListDate === ''){
                        param = {
                            date_type:'week',
                            page: this.adminPages.currentPageNum,
                            limit: this.adminPages.eachPageNum //页面显示条数
                        };
                    }else{
                        param = {
                            date_type:'week',
                            date:this.isListDate,
                            page: this.adminPages.currentPageNum,
                            limit: this.adminPages.eachPageNum //页面显示条数
                        };
                    }
                } else if(this.radio == '月榜'){
                    if(this.isListDate === ''){
                        param = {
                            date_type:'month',
                            page: this.adminPages.currentPageNum,
                            limit: this.adminPages.eachPageNum //页面显示条数
                        };
                    } else {
                        param = {
                            date_type:'month',
                            date:this.isListDate,
                            page: this.adminPages.currentPageNum,
                            limit: this.adminPages.eachPageNum //页面显示条数
                        };
                    }
                }
                this.$httpStudent.axiosGetBy(this.$api.getTakeGoodsVideoList,param,(res)=>{
                    console.log(341,res)
                    if(res.code === 200){
                        if(res.data.data){
                            this.productList = res.data.data;
                            this.adminPages.total = res.data.total
                        }
                    }
                })
            },
            // 获取电商视频分类
            getGoodsVideoClass(){
                let param = {};
                this.$httpStudent.axiosGetBy(this.$api.getGoodsVideoClass,param,(res)=>{
                    if(res.code === 200){
                        this.GoodsVideoClassList = res.data
                    }
                })
            },
            // 选择日期
            selectDayDate(val){
                let timeDay = val;
                console.log('日榜',val);
                this.isListDate = timeDay;
                let param = {
                    date_type:'day',
                    date:timeDay,
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum //页面显示条数
                };
                this.$httpStudent.axiosGetBy(this.$api.getTakeGoodsVideoList,param,(res)=>{
                    if(res.code === 200){
                        if(res.data.data){
                            this.productList = res.data.data
                        }
                    }
                })
            },
            // 选择周
            selectWeekDate(val){
                let firstDay = new Date(val.getFullYear(), 0, 1); //2020-01-01
                let dayOfWeek = firstDay.getDay();  //2020-01-01是周三  dayOfWeek==3
                let spendDay = 1;
                if (dayOfWeek != 0) {
                    spendDay = 7 - dayOfWeek + 1   // 5 离下周一还有5天
                }
                firstDay = new Date(val.getFullYear(), 0, 1 + spendDay);   // 2020-01-06是2020年第一个周一，是2020年第二个周
                let d = Math.ceil((val.valueOf() - firstDay.valueOf()) / 86400000);   // 当前时间距离2020-01-06有几天
                let result = Math.ceil(d / 7);   // 换算成周为单位 2020-01-06result是0,但其实是第二个周，所以默认加2
                let year = val.getFullYear();
                let week = result + 2 //如果使用的是默认为加2（如果将自然周设置为周一到周日则是加1）
                let startTime = this.dateFormatTwo(val.valueOf() - 86400000);   // 开始时间
                let endTime = this.dateFormatTwo(val.valueOf() + 5*86400000); //    结束时间
                let finish = endTime.substring(0,10);
                this.lweekVal = finish;
                console.log('周榜',this.lweekVal);
                this.isListDate = finish;
                let param ={
                    date_type:'week',
                    date:this.lweekVal,
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum //页面显示条数
                }
                this.$httpStudent.axiosGetBy(this.$api.getTakeGoodsVideoList,param,(res)=>{
                    if(res.code === 200){
                        if(res.data.data){
                            this.productList = res.data.data
                        }
                    }
                })
            },
            // 选择月
            selectMonthDate(val){
                let date = new Date();
                let dateTimeFormatTime = this.dateFormatTwo(date);
                let nowTime = dateTimeFormatTime.substring(0,7); //当前年月
                let nowMothTime = dateTimeFormatTime.substring(0,10);
                if(val == nowTime){
                    console.log('月榜',nowMothTime);
                    this.isListDate = nowMothTime;
                    let param = {
                                date_type:'month',
                                date:nowMothTime,
                                page: this.adminPages.currentPageNum,
                                limit: this.adminPages.eachPageNum //页面显示条数
                            }
                            this.$httpStudent.axiosGetBy(this.$api.getTakeGoodsVideoList,param,(res)=>{
                                console.log(420,res)
                                if(res.code === 200){
                                    if(res.data.data){
                                        if(res.data.data){
                                            this.productList = res.data.data
                                        }
                                    }
                                }
                            })
                } else {
                    let str = '-20';
                    let timeVal = val.concat(str);
                    console.log(428,timeVal)
                    this.isListDate = timeVal;
                    let param = {
                        date_type:'month',
                        date:timeVal,
                        page: this.adminPages.currentPageNum,
                        limit: this.adminPages.eachPageNum //页面显示条数
                    }
                    this.$httpStudent.axiosGetBy(this.$api.getTakeGoodsVideoList,param,(res)=>{
                        console.log(436,res)
                        if(res.code === 200){
                            if(res.data.data){
                                if(res.data.data){
                                    this.productList = res.data.data
                                }
                            }
                        }
                    })
                }
            },
            toVideoDetail(row){
                this.$router.push({
                    path:'/student/ds/video/videodetail',
                    query:{options:JSON.stringify(row)}
                })
            },
            toVideoDetailTwo(row){
                this.$router.push({
                    path:'/student/ds/video/videodetail',
                    query:{options:JSON.stringify(row)}
                })
            },
            toAnalysis(row,goodsAnalyzes){
                this.$router.push({
                    path:'/student/ds/video/videodetail',
                    query:{options:JSON.stringify(row),name:goodsAnalyzes}
                })
            }
        },
    }
</script>

<style scoped lang="scss">
    .dropdownItem{
        &:hover{
            background: #ffffff;
            color: #1E33E3;
        }
    }
    .dsVideoRank{
        /*width: calc(100% - 280px);*/
        width: 101%;
        /*padding: 20px 20px 0 0;*/
        /*overflow: scroll;*/
        .dsVideoRank-header{
            background: #ffffff;
            overflow: hidden;
            .dsVideoRank-underline{
                display: flex;
                margin-left: 14px;
                margin-right: 14px;
                /*margin-top: 14px;*/
                border-bottom: 1px solid rgba(234,234,234,1);
            }
            .listChoices{
                margin-left: 12px;
                margin-top: 23px;
                margin-bottom: 24px;
            }
            .dateSelect{
                margin-left: 22px;
                margin-top: 23px;
            }
            .searchContent{
                margin-top: 23px;
                margin-left: 23px;
                ::v-deep .searchInput{
                    width: 389px;
                    background: #FFF3F3F6;
                    color: rgba(153,153,153,1);
                }
                ::v-deep .searchInput::-webkit-input-placeholder{
                    color: rgba(153,153,153,1);
                }
            }
            .commodityClassify{
                width: 100%;
                display: inline-block;
                margin-top: 22px;
                overflow: hidden;
                background: #ffffff;
                color: rgba(102,102,102,1);
                font-size:14px;
                .commodityClassify-underline{
                    margin-left: 27px;
                    margin-right: 12px;
                    border-bottom: 1px solid rgba(234,234,234,1);
                    margin-bottom: 0;
                    .commodityClassify-box{
                        ::v-deep.el-dropdown{
                            line-height: 30px;
                            border: 1px solid #ffffff;
                            &:hover .el-icon-arrow-down{
                                transition: 0.5s;
                                transform: rotate(180deg);
                                color: #1E33E3!important;
                            }
                        }
                        ::v-deep.elDropdown{
                            border: 1px solid #1E33E3;
                            border-radius: 4px;
                            color: #1E33E3;
                            .el-icon-arrow-down{
                                transition: 0.5s;
                                transform: rotate(180deg);
                                color: #1E33E3!important;
                            }
                        }
                    }
                    .span-spfl{
                        color: rgba(51,51,51,1);
                    }
                    .span-all{
                        width: 55px;
                        text-align: center;
                        border-radius: 4px;
                        line-height: 30px;
                        &:hover{
                            border:1px solid rgba(35,56,230,1);
                            color: rgba(35,56,230,1);
                        }
                    }
                    .span-alls{
                        width: 55px;
                        text-align: center;
                        border-radius: 4px;
                        line-height: 30px;
                        border:1px solid rgba(35,56,230,1);
                        color: rgba(35,56,230,1);
                    }
                    span{
                        display: inline-block;
                        /*margin-bottom: 56px;*/
                    }
                    .fill{
                        height: 56px;
                    }
                }
            }
            ::v-deep.el-table td {
                border-bottom: 8px solid #F3F3F6;
            }
            .tableList{
                .videoImg{
                    width: 100px;
                    height: 130px;
                }
                .ranking-num{
                    color: rgba(51,51,51,1);
                    font-size: 18px;
                }
                .describe-title{
                    color: #333333;
                    font-size: 16px;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 1;
                }
                .describe-content{
                    width: 89%;
                    color: #333333;
                    font-size: 14px;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 1;
                    span{
                        color: #ffcc66;
                    }
                }
                .likeAdd-like{
                    display: inline-block;
                    color: #333333;
                    font-size: 14px;
                }
                .likeAdd-add{
                    display: inline-block;
                    width: 60px;
                    height: 28px;
                    background: rgba(255,240,241,1);
                    font-size: 12px;
                    color: #FF606E;
                    text-align: center;
                    line-height: 28px;
                    margin-left: 8px;
                }
                .author-name{
                    line-height: 1;
                    margin-top: 0;
                    text-align: left;
                    font-size: 14px;
                    color: #333333;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-align: center;
                }
                .author-fans{
                    line-height: 1;
                    margin-top:-10px;
                    text-align: left;
                    font-size: 12px;
                    color: #999999;
                    text-align: center;
                }
                .data-icon{
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    background: #4B5EF1;
                    color: #ffffff;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 28px;
                }
                .play-icon{
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    background: #FF0707;
                    color: #ffffff;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 28px;
                    margin-left: 6px;
                }
                .chart-icon{
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    background: #FFB624;
                    color: #ffffff;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 28px;
                    margin-left: 6px;
                }
            }
        }
    }
</style>